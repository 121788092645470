import { Card, Modal } from 'antd';
import classnames from 'classnames';
import type { PaginationData } from 'egenie-utils';
import { ExportModal, MainSubStructure, MainSubStructureModel, NormalProgrammeComponent, NormalProgramme, request, TimeStampFormatter, ExportStore } from 'egenie-utils';
import { action, values } from 'mobx';
import React from 'react';
import styles from './index.less';
import { ReceivingRecordDetailModal, receivingRecordDetailStore } from './receivingRecordDetailModal';
import type { FindSummaryInterface } from './types';

class ReceivingRecordMainStore {
  constructor() {
    this.normalProgramme = new NormalProgramme({
      count: 4,
      filterItems: [
        {
          field: 'arrivalTime',
          type: 'dateRange',
          label: '到货时间',
          startParamsField: 'arrivalBegin',
          endParamsField: 'arrivalEnd',
          allowEmpty: [
            false,
            false,
          ],
        },
        {
          field: 'goodsArrivalType',
          type: 'select',
          label: '到货类型',
          data: [
            {
              label: '采购到货',
              value: '0',
            },
            {
              label: '采购退货',
              value: '1',
            },
          ],
        },
      ],
      handleSearch: this.mainSubStructureModel.onQuery,
    });
    this.mainSubStructureModel.getFilterParams = () => this.normalProgramme.filterItems.params;
  }

  public normalProgramme: NormalProgramme;

  public exportStore: ExportStore = new ExportStore({ parent: null });

  public mainSubStructureModel = new MainSubStructureModel({
    buttons: [
      {
        text: '导出',
        icon: 'icon-export',
        handleClick: () => {
          const ids = values(this.mainSubStructureModel.gridModel.selectedIds).join(',');
          const fileName = '到货单';
          const exportType = 'cloud_goods_arrival_record';
          const queryParam = this.normalProgramme.filterItems.params;
          const queryParamShow = this.normalProgramme.filterItems.translateParams.join(' ');

          if (ids) {
            this.exportStore.onShow(fileName, exportType, ids, queryParam, queryParamShow);
          } else {
            Modal.confirm({
              title: '提示',
              content: '未选择数据将导出全部数据?',
              onOk: () => {
                this.exportStore.onShow(fileName, exportType, '', queryParam, queryParamShow);
              },
            });
          }
        },
      },
    ],
    grid: {
      columns: [
        {
          width: 200,
          key: 'goodsArrivalSummaryNo',
          name: '到货单号',
          formatter: ({ row }) => {
            return (
              <div className={styles.arrival}>
                <span>
                  {row.goodsArrivalSummaryNo}
                </span>
                <i
                  className={classnames([
                    'icon-sign_xiang',
                    styles.icon,
                  ])}
                  onClick={this.onDetailClick.bind(this, row)}
                />
              </div>
            );
          },
        },
        {
          key: 'goodsArrivalTypeName',
          name: '类型',
          width: 80,
        },
        {
          width: 150,
          key: 'createTime',
          name: '创建时间',
          formatter: ({ row }) => <TimeStampFormatter value={row.createTime}/>,
        },
        {
          key: 'creatorName',
          name: '创建人',
          width: 150,
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: false,
      showReset: false,
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/scanGoods/receivingRecord/index/normal',
    },
    api: {
      onQuery: (params) => {
        const {
          filterParams = {},
          ...rest
        } = params;

        return request<PaginationData<FindSummaryInterface>>({
          method: 'POST',
          url: '/api/cloud/wms/rest/goods/arrival/find/summary',
          data: {
            ...filterParams,
            ...rest,
          },
        });
      },
    },
    hiddenSubTable: true,
  });

  @action public onDetailClick = (row: FindSummaryInterface) => {
    receivingRecordDetailStore.visible = true;
    receivingRecordDetailStore.goodsArrivalSummaryNo = row.id;
    receivingRecordDetailStore.queryTableDetailData();
  };
}

export class ReceivingRecord extends React.Component {
  public ReceivingRecordMainStore = new ReceivingRecordMainStore();

  render() {
    const {
      normalProgramme,
      mainSubStructureModel,
      exportStore,
    } = this.ReceivingRecordMainStore;
    return (
      <>
        <ExportModal store={exportStore}/>
        <Card size="small">
          <NormalProgrammeComponent store={normalProgramme}/>
        </Card>
        <div className={styles.body}>
          <MainSubStructure store={mainSubStructureModel}/>
        </div>
        <ReceivingRecordDetailModal/>
      </>
    );
  }
}
