import { SearchOutlined } from '@ant-design/icons';
import { Input, Modal } from 'antd';
import type { PaginationData } from 'egenie-utils';
import { EgGrid, EgGridModel, ImgFormatter, request } from 'egenie-utils';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';
import type { DetailInterface } from './types';

class ReceivingRecordDetailStore {
  @observable public visible = false;

  @observable public detailsNo = '';

  public gridDetailStore = new EgGridModel({
    columns: [
      {
        key: 'pic',
        name: '图片',
        formatter: ({ row }) => <ImgFormatter value={row.pic}/>,
        width: 50,
      },
      {
        key: 'goodsCode',
        name: '商品编码',
        width: 200,
      },
      {
        key: 'skuNo',
        name: 'sku编码',
        width: 180,
      },
      {
        key: 'barCode',
        name: '条形码',
        width: 180,
      },
      {
        key: 'uniqueCode',
        name: '唯一码',
        width: 180,
      },
      {
        key: 'saleOrderNo',
        name: '订单号',
        width: 180,
      },
      {
        key: 'pmsPurchaseOrderNo',
        name: '采购单号',
        width: 180,
      },
      {
        key: 'color',
        name: '颜色',
        width: 100,
      },
      {
        key: 'size',
        name: '尺码',
        width: 100,
      },
      {
        key: 'ownerName',
        name: '货主',
        width: 200,
      },
    ].map((v) => ({
      resizable: true,
      sortable: false,
      ...v,
    })),
    rows: [],
    primaryKeyField: 'id',
    sortByLocal: false,
    showCheckBox: false,
    showEmpty: false,
    showReset: false,
    setColumnsDisplay: true,
    gridIdForColumnConfig: '/egenie-cloud-wms/scanGoods/receivingRecord/index/detailModal',
    api: {
      onRefresh: () => {
        this.queryTableDetailData();
      },
      onShowSizeChange: () => {
        this.queryTableDetailData();
      },
      onPageChange: () => {
        this.queryTableDetailData();
      },
    },
  });

  public goodsArrivalSummaryNo = null; // 到货记录ID

  @action
  public onDetailsNoChange = (e) => {
    this.detailsNo = e.target.value.trim();
  };

  @action public queryTableDetailDataEnter = (e) => {
    this.gridDetailStore.clearToOriginal();
    this.queryTableDetailData();
  };

  /**
   * 查询到货明细
   */
  @action
  public queryTableDetailData = async() => {
    this.gridDetailStore.resetAll();
    this.gridDetailStore.loading = true;
    try {
      const {
        current,
        pageSize,
      } = this.gridDetailStore;
      const req = await request<PaginationData<DetailInterface>>({
        method: 'POST',
        url: '/api/cloud/wms/rest/goods/arrival/find/details',
        data: {
          page: current,
          pageSize,
          goodsArrivalSummaryId: this.goodsArrivalSummaryNo,
          no: this.detailsNo.trim(),
        },
      });
      this.gridDetailStore.rows = req?.data?.list || [];
      this.gridDetailStore.total = req?.data?.totalCount || 0;
    } finally {
      this.gridDetailStore.loading = false;
    }
  };

  @action
  public onCancel = () => {
    this.visible = false;
    this.detailsNo = '';
    this.goodsArrivalSummaryNo = null;
    this.gridDetailStore.clearToOriginal();
    this.gridDetailStore.resetAll();
  };
}

export const receivingRecordDetailStore = new ReceivingRecordDetailStore();

@observer
export class ReceivingRecordDetailModal extends React.Component {
  render() {
    const {
      visible,
      onCancel,
      onDetailsNoChange,
      queryTableDetailDataEnter,
      detailsNo,
      gridDetailStore,
    } = receivingRecordDetailStore;
    return (
      <Modal
        centered
        className={styles.detail}
        footer={null}
        onCancel={onCancel}
        title="到货明细"
        visible={visible}
        width={1500}
      >
        <div className={styles.detailBody}>
          <Input
            allowClear
            className={styles.detailQuery}
            onChange={onDetailsNoChange}
            onPressEnter={queryTableDetailDataEnter}
            placeholder="可查询唯一码、sku编码、商品条码"
            suffix={<SearchOutlined onClick={queryTableDetailDataEnter}/>}
            value={detailsNo}
          />
          <div>
            <EgGrid store={gridDetailStore}/>
          </div>
        </div>
      </Modal>
    );
  }
}
