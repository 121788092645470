import { Tabs } from 'antd';
import React from 'react';
import styles from './index.less';
import { ReceivingAbnormal } from './receivingAbnormal';
import { ReceivingRecord } from './receivingRecord';

export default function() {
  return (
    <div className={styles.page}>
      <Tabs defaultActiveKey="receivingRecord">
        <Tabs.TabPane
          key="receivingRecord"
          tab="到货记录"
        >
          <ReceivingRecord/>
        </Tabs.TabPane>
        <Tabs.TabPane
          key="receivingAbnormal"
          tab="到货异常"
        >
          <ReceivingAbnormal/>
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}
