import { Card, Modal } from 'antd';
import type { PaginationData } from 'egenie-utils';
import { ExportModal, MainSubStructure, MainSubStructureModel, NormalProgrammeComponent, NormalProgramme, request, TimeStampFormatter, ExportStore } from 'egenie-utils';
import { values } from 'mobx';
import React from 'react';
import styles from './index.less';
import type { ReceivingAbnormalItem } from './types';

class ReceivingAbnormalMainStore {
  constructor() {
    this.normalProgramme = new NormalProgramme({
      count: 6,
      filterItems: [
        {
          field: 'uniqueCode',
          type: 'input',
          label: '唯一码',
        },
        {
          field: 'skuNo',
          type: 'input',
          label: 'SKU编码',
        },
        {
          field: 'dateType',
          type: 'select',
          label: '时间类型',
          data: [
            {
              label: '第一次到货时间',
              value: 'firstArrivalTime',
            },
            {
              label: '最后一次到货时间',
              value: 'lastArrivalTime',
            },
          ],
        },
        {
          field: 'dateValue',
          type: 'dateRange',
          label: '到货时间',
        },

      ],
      handleSearch: this.mainSubStructureModel.onQuery,
    });
    this.mainSubStructureModel.getFilterParams = () => this.normalProgramme.filterItems.params;
  }

  public normalProgramme: NormalProgramme;

  public exportStore: ExportStore = new ExportStore({ parent: null });

  public mainSubStructureModel = new MainSubStructureModel({
    buttons: [
      {
        text: '导出',
        icon: 'icon-export',
        handleClick: () => {
          const ids = values(this.mainSubStructureModel.gridModel.selectedIds).join(',');
          const fileName = '到货异常单';
          const exportType = 'cloud_goods_arrival_unusual_record';
          const queryParam = this.normalProgramme.filterItems.params;
          const queryParamShow = this.normalProgramme.filterItems.translateParams.join(' ');

          if (ids) {
            this.exportStore.onShow(fileName, exportType, ids, queryParam, queryParamShow);
          } else {
            Modal.confirm({
              title: '提示',
              content: '未选择数据将导出全部数据?',
              onOk: () => {
                this.exportStore.onShow(fileName, exportType, '', queryParam, queryParamShow);
              },
            });
          }
        },
      },
    ],
    grid: {
      columns: [
        {
          key: 'uniqueCode',
          name: '唯一码',
          width: 200,
        },
        {
          key: 'skuNo',
          name: 'SKU编码',
          width: 200,
        },
        {
          key: 'color',
          name: '颜色',
          width: 100,
        },
        {
          key: 'size',
          name: '尺码',
          width: 100,
        },
        {
          key: 'arrivalFrequency',
          name: '扫描次数',
          width: 80,
        },
        {
          key: 'firstArrivalTime',
          name: '第一次到货时间',
          width: 150,
          formatter: ({ row }) => <TimeStampFormatter value={row.firstArrivalTime}/>,
        },
        {
          key: 'lastArrivalTime',
          name: '最后一次到货时间',
          width: 150,
          formatter: ({ row }) => <TimeStampFormatter value={row.lastArrivalTime}/>,
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: false,
      showReset: false,
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/scanGoods/receivingRecord/index/abnormal',
    },
    api: {
      onQuery: (params) => {
        const {
          filterParams = {},
          ...rest
        } = params;
        return request<PaginationData<ReceivingAbnormalItem>>({
          method: 'POST',
          url: '/api/cloud/wms/rest/goods/arrival/find/unusual/details',
          data: {
            ...filterParams,
            ...rest,
          },
        });
      },
    },
    hiddenSubTable: true,
  });
}

export class ReceivingAbnormal extends React.Component {
  public receivingAbnormalMainStore = new ReceivingAbnormalMainStore();

  render() {
    const {
      normalProgramme,
      mainSubStructureModel,
      exportStore,
    } = this.receivingAbnormalMainStore;
    return (
      <>
        <Card size="small">
          <NormalProgrammeComponent store={normalProgramme}/>
        </Card>
        <div className={styles.body}>
          <MainSubStructure store={mainSubStructureModel}/>
        </div>
        <ExportModal store={exportStore}/>
      </>
    );
  }
}
